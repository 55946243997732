<template>
<div class="team">
    <div class="center" style="text-align:center;"><br/>
      <h4 class="team-above-main-title">MEET OUR TEAM</h4>
      <h1 class="team-main-title medium-title">Let us introduce ourselves</h1>
      <p class="team-sub-title">Our staff are known for their friendly and knowledgeable customer service.</p>
    </div><br/>

    <div class="center">
      <v-row align="center" justify="center">
        <v-col cols="12" sm="6" md="4" lg="3" v-for="team in teams" :key="team.id">
          <div class="person-image-div">
            <v-img :src="team.image" class="person-img rounded-tr-xl rounded-bl-xl" />
            
            <div class="text-div rounded-tr-xl rounded-bl-xl">
              <div class="text">
                <h2 style="margin-bottom:5px;">{{ team.name }}</h2>
                <div class="d-flex">
                  <hr class="design1" />
                  <span class="design2">{{ team.post }}</span>
                  <hr class="design3" />
                </div>
                <div style="margin-top:10px;">
                  <a :href="team.linkedin" style="text-decoration:none; margin-right:7px;" target="_blank">
                    <v-icon color="#007BB5">
                      mdi-linkedin
                    </v-icon>
                  </a> 
                  <a :href="team.facebook" style="text-decoration:none;" target="_blank">
                    <v-icon color="#3B5998">
                      mdi-facebook
                    </v-icon>
                  </a>   
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div><br/><br/>

</div>
</template>

<script>
export default {
  data() {
    return {
      teams:[
        {
          id: 1,
          name: "Bhushan Shrestha",
          image: require("@/assets/img/bhushan.png"),
          post: "Managing Director/Co-founder",
          linkedin: "https://www.linkedin.com/in/bhushan-shrestha-0a7aaa157/",
          facebook: "https://www.facebook.com/giggs.bhushan"
        },
        {
          id: 2,
          name: "Nashib Acharya",
          image: require("@/assets/img/nashib.png"),
          post: "Co-founder",
          linkedin: "https://www.linkedin.com/in/nashib-acharya-06214b15a/",
          facebook: "https://www.facebook.com/nasibacharya"
        },
        {
          id: 3,
          name: "Kishan Shrestha",
          image: require("@/assets/img/kishan.png"),
          post: "Co-founder",
          linkedin: "https://www.linkedin.com/",
          facebook: "https://www.facebook.com/kish.stha"
        },
        {
          id: 4,
          name: "Alish Dahal",
          image: require("@/assets/img/alish.jpg"),
          post: "Senior Full Stack Developer",
          linkedin: "https://www.linkedin.com/in/alish-dahal-50577420b/",
          facebook: "https://www.facebook.com/alishdahal2"
        },
        {
          id: 5,
          name: 'Saurav Thakur',
          image:require("@/assets/img/saurav.png"),
          post: "Full Stack Developer",
          linkedin: "https://www.linkedin.com/in/sauravthakurofficial/",
          facebook: "https://www.facebook.com/sauravthakurofficial"
        },
        {
          id: 6,
          name: 'Nishma Lama',
          image:require("@/assets/img/nishma.jpg"),
          post: "Front End Developer",
          linkedin: "https://www.linkedin.com/",
          facebook: "https://www.facebook.com/"
        },
        {
          id: 7,
          name: 'Aakash Rai',
          image:require("@/assets/img/aakash.png"),
          post: "Graphic Designer",
          linkedin: "https://www.linkedin.com/in/aakash-rai-620a901bb/",
          facebook: "https://www.facebook.com/aakash.rai.12914216"
        },
        {
          id: 8,
          name: 'Aayush Shrestha',
          image:require("@/assets/img/aayush.jpg"),
          post: "App Developer",
          linkedin: "https://www.linkedin.com/in/aayush-shrestha-75090b212/",
          facebook: "https://www.facebook.com/AKAaayush"
        },
        {
          id: 9,
          name: 'Kriti Manandhar',
          image:require("@/assets/img/kriti.jpg"),
          post: "Jr. Developer",
          linkedin: "https://www.linkedin.com/in/kriti-manandhar-332410172",
          facebook: "https://www.facebook.com/profile.php?id=100018818220284"
        }
      ]
    }
  }
}
</script>

<style scoped>
.team{
  background: #F3F3F3;
}
.design1, .design3 {
  width: 50px;
  background: #808080;
  margin: auto;
}
.design2 {
  margin: 0 5px;
  color:#808080;
  font-weight: 500;
}
.team-main-title{
  color:#2D2C2C;
  margin-bottom:5px;
}
.team-above-main-title{
  color:#ABABAC;
  letter-spacing: 3px;
}
.team-sub-title{
  color:#ABABAC;
  margin:5px 0;
}
.person-image-div{
  width:90%;
  margin:10px auto;
  height:400px;
  position: relative;
}
.person-img{
  width:100%;
  height:100%;
}

.person-image-div:hover .text-div {
  opacity: 1;
}

.text-div {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 94%;
  width: 94%;
  margin: 3%;
  opacity: 0;
  transition: .5s ease;
  background-color: rgba(255, 255, 255, 0.8);
}

.text {
  color: #2D2C2C;
  position: absolute;
  top: 50%;
  left: 50%;
  width:100%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding:20px;
}

</style>