<template>
  <div class="about">
    <top-banner 
      title="Our Company" 
      subtitle="Enhance and grow your career by applying to work with STS's experienced experts" 
      :topimage="image"
    />
    
    <navbar/>

    <read />

    <our-team />
  </div>
</template>

<script>
import image from "./../assets/img/group.jpg"
import TopBanner from '../components/TopBanner.vue'
import Navbar from '../components/Navbar.vue'
import Read from '../components/Read.vue'
import OurTeam from '../components/OurTeam.vue'

export default {
  name: 'Team',
  components: {
    TopBanner,
    Navbar,
    Read,
    OurTeam,
    
  },
  data() {
    return{
      image,
    }
  }
}
</script>
